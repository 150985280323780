import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsIcon from '@mui/icons-material/Notifications';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router';
import { getUserDetails, logout } from '../actions/userActions';

import UserAvatar from './UserAvatar';

import { LiveChatWidget } from '@livechat/widget-react';
import { Badge } from '@mui/material';
import CD_LOGO_130 from '../img/cd-logo-130.png';
import { getNotificationList } from '../actions/notificationActions';
import useElementHeight from '../hooks/useElementHeight';
import { SET_HEADER_HEIGHT } from '../constants/appConstants';
import { useEffect, useState } from 'react';

const Header = ({ theme, setLogOutClicked }) => {
  const dispatch = useDispatch();

  const notificationList = useSelector((state) => state.notificationList);
  const { loading, error, notifications } = notificationList;

  const userDetails = useSelector((state) => state.userDetails);
  const { user, loading: loadingDetails } = userDetails;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [anchorElNotification, setAnchorElNotification] = useState(null);

  useEffect(() => {
    dispatch(getUserDetails());
    dispatch(getNotificationList());
  }, []);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleOpenNotificationMenu = (event) => {
    setAnchorElNotification(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogout = () => {
    handleCloseUserMenu();
    setLogOutClicked(true);
    dispatch(logout());
  };

  const headerButtonStyle = {
    my: 2,
    color: theme.palette.primary.main,
    display: 'block',
    '&:hover': {
      backgroundColor: '#FFF4E5',
    },
  };
  const headerButtonActiveStyle = {
    my: 2,
    color: '#FFF',
    display: 'block',
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.primary.main,
      backgroundColor: '#FFF4E5',
    },
  };

  const headerMobileLinkStyle = {
    color: theme.palette.primary.main,
  };
  const headerMobileLinkActiveStyle = {
    color: '#FFF',
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.primary.main,
    },
  };

  const [visibility, setVisibility] = useState('minimized');

  const chatVisibilityHandler = () => {
    if (visibility === 'minimized') {
      setVisibility('maximized');
    } else {
      setVisibility('minimized');
    }
  };

  const authLinks = (
    <Box
      sx={{
        flexGrow: 1,
        display: { xs: 'none', md: 'flex' },
        gap: '5px',
      }}
    >
      <NavLink to="/feed" style={{ textDecoration: 'none' }}>
        {({ isActive }) => (
          <Button
            onClick={handleCloseNavMenu}
            sx={isActive ? headerButtonActiveStyle : headerButtonStyle}
          >
            Live Feed
          </Button>
        )}
      </NavLink>

      <NavLink to="/masseuses" style={{ textDecoration: 'none' }}>
        {({ isActive }) => (
          <Button
            onClick={handleCloseNavMenu}
            sx={isActive ? headerButtonActiveStyle : headerButtonStyle}
          >
            Masseuses
          </Button>
        )}
      </NavLink>

      <NavLink to="/profile" style={{ textDecoration: 'none' }}>
        {({ isActive }) => (
          <Button
            onClick={handleCloseNavMenu}
            sx={isActive ? headerButtonActiveStyle : headerButtonStyle}
          >
            My Profile
          </Button>
        )}
      </NavLink>

      <NavLink to="/" style={{ textDecoration: 'none' }}>
        {({ isActive }) => (
          <Button
            onClick={handleCloseNavMenu}
            sx={isActive ? headerButtonActiveStyle : headerButtonStyle}
          >
            My Rewards
          </Button>
        )}
      </NavLink>

      <NavLink to="/sessions" style={{ textDecoration: 'none' }}>
        {({ isActive }) => (
          <Button
            onClick={handleCloseNavMenu}
            sx={isActive ? headerButtonActiveStyle : headerButtonStyle}
          >
            My Sessions
          </Button>
        )}
      </NavLink>

      <NavLink to="/schedule" style={{ textDecoration: 'none' }}>
        {({ isActive }) => (
          <Button
            onClick={handleCloseNavMenu}
            sx={isActive ? headerButtonActiveStyle : headerButtonStyle}
          >
            Schedule
          </Button>
        )}
      </NavLink>

      <Button onClick={() => chatVisibilityHandler()}>
        {visibility === 'maximized' ? 'Close Chat Window' : 'Book Appointment'}
      </Button>

      <NavLink to="/services-rates" style={{ textDecoration: 'none' }}>
        {({ isActive }) => (
          <Button
            onClick={handleCloseNavMenu}
            sx={isActive ? headerButtonActiveStyle : headerButtonStyle}
          >
            Services & Rates
          </Button>
        )}
      </NavLink>

      <NavLink to="/feedbacks" style={{ textDecoration: 'none' }}>
        {({ isActive }) => (
          <Button
            onClick={handleCloseNavMenu}
            sx={isActive ? headerButtonActiveStyle : headerButtonStyle}
          >
            Feedback
          </Button>
        )}
      </NavLink>
      {/* <NavLink to={'/partner-offers'}>
        <Button
          onClick={handleCloseNavMenu}
          sx={
            location.pathname === '/partner-offers'
              ? headerButtonActiveStyle
              : headerButtonStyle
          }
        >
          Partner Offers
        </Button>
      </NavLink> */}
    </Box>
  );

  const [toolbarRef, toolbarHeight] = useElementHeight();

  useEffect(() => {
    if (toolbarHeight > 0) {
      dispatch({
        type: SET_HEADER_HEIGHT,
        payload: toolbarHeight,
      });
    }
  }, [toolbarHeight, dispatch]);

  return (
    <AppBar
      position="fixed"
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        backgroundColor: '#FFF',
      }}
      ref={toolbarRef}
    >
      <LiveChatWidget
        license="11109027"
        visibility={visibility}
        customerName={user ? user.name : ''}
        customerEmail={user ? user.email : ''}
        sessionVariables={{
          name: user ? user.name : '',
          email: user ? user.email : '',
          phone: user ? user.phone : '',
        }}
      />
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <NavLink to={'/'}>
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ mr: 2, display: { xs: 'none', md: 'flex' } }}
            >
              <img
                src="https://clubdynasty.ca/wp-content/uploads/2019/07/logo_dynasty-130x63.png"
                alt="logo"
              />
            </Typography>{' '}
          </NavLink>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color={anchorElNav ? 'primary' : 'default'}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              <NavLink to={'/feed'} style={{ textDecoration: 'none' }}>
                {({ isActive }) => (
                  <MenuItem
                    onClick={handleCloseNavMenu}
                    sx={
                      isActive
                        ? headerMobileLinkActiveStyle
                        : headerMobileLinkStyle
                    }
                  >
                    <Typography textAlign="center">Live Feed</Typography>
                  </MenuItem>
                )}
              </NavLink>

              <NavLink to={'/masseuses'} style={{ textDecoration: 'none' }}>
                {({ isActive }) => (
                  <MenuItem
                    onClick={handleCloseNavMenu}
                    sx={
                      isActive
                        ? headerMobileLinkActiveStyle
                        : headerMobileLinkStyle
                    }
                  >
                    <Typography textAlign="center">Masseuses</Typography>
                  </MenuItem>
                )}
              </NavLink>

              <NavLink to={'/profile'} style={{ textDecoration: 'none' }}>
                {({ isActive }) => (
                  <MenuItem
                    onClick={handleCloseNavMenu}
                    sx={
                      isActive
                        ? headerMobileLinkActiveStyle
                        : headerMobileLinkStyle
                    }
                  >
                    <Typography textAlign="center">My Profile</Typography>
                  </MenuItem>
                )}
              </NavLink>

              <NavLink to={'/'} style={{ textDecoration: 'none' }}>
                {({ isActive }) => (
                  <MenuItem
                    onClick={handleCloseNavMenu}
                    sx={
                      isActive
                        ? headerMobileLinkActiveStyle
                        : headerMobileLinkStyle
                    }
                  >
                    <Typography textAlign="center">My Rewards</Typography>
                  </MenuItem>
                )}
              </NavLink>

              <NavLink to={'/sessions'} style={{ textDecoration: 'none' }}>
                {({ isActive }) => (
                  <MenuItem
                    onClick={handleCloseNavMenu}
                    sx={
                      isActive
                        ? headerMobileLinkActiveStyle
                        : headerMobileLinkStyle
                    }
                  >
                    <Typography textAlign="center">My Sessions</Typography>
                  </MenuItem>
                )}
              </NavLink>

              <NavLink to={'/schedule'} style={{ textDecoration: 'none' }}>
                {({ isActive }) => (
                  <MenuItem
                    onClick={handleCloseNavMenu}
                    sx={
                      isActive
                        ? headerMobileLinkActiveStyle
                        : headerMobileLinkStyle
                    }
                  >
                    <Typography textAlign="center">Schedule</Typography>
                  </MenuItem>
                )}
              </NavLink>

              <MenuItem
                onClick={() => {
                  handleCloseNavMenu();
                  chatVisibilityHandler();
                }}
                sx={headerMobileLinkStyle}
              >
                <Typography textAlign="center">Book Appointment</Typography>
              </MenuItem>

              <NavLink
                to={'/services-rates'}
                style={{ textDecoration: 'none' }}
              >
                {({ isActive }) => (
                  <MenuItem
                    onClick={handleCloseNavMenu}
                    sx={
                      isActive
                        ? headerMobileLinkActiveStyle
                        : headerMobileLinkStyle
                    }
                  >
                    <Typography textAlign="center">Services & Rates</Typography>
                  </MenuItem>
                )}
              </NavLink>

              <NavLink to={'/feedbacks'} style={{ textDecoration: 'none' }}>
                {({ isActive }) => (
                  <MenuItem
                    onClick={handleCloseNavMenu}
                    sx={
                      isActive
                        ? headerMobileLinkActiveStyle
                        : headerMobileLinkStyle
                    }
                  >
                    <Typography textAlign="center">Feedback</Typography>
                  </MenuItem>
                )}
              </NavLink>
              {/* <NavLink to={'/partner-offers'}>
                <MenuItem
                  onClick={handleCloseNavMenu}
                  sx={
                    location.pathname === '/partner-offers'
                      ? headerMobileLinkActiveStyle
                      : headerMobileLinkStyle
                  }
                >
                  <Typography textAlign="center">Partner Offers</Typography>
                </MenuItem>
              </NavLink> */}
            </Menu>
          </Box>

          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}
          >
            <NavLink to={'/'}>
              <img src={CD_LOGO_130} alt="logo" />{' '}
            </NavLink>
          </Typography>

          {authLinks}

          <Box sx={{ flexGrow: 0, marginRight: '10px' }}>
            {/* <NavLink to={'/notifications'}>
              <Tooltip title="Open notifications">
                <IconButton onClick={handleOpenNotificationMenu} sx={{ p: 0 }}>
                  <Badge
                    badgeContent={notifications?.unreadCount}
                    color="primary"
                  >
                    <NotificationsIcon />
                  </Badge>
                </IconButton>
              </Tooltip>
            </NavLink> */}
            <NavLink to={'/notifications'} style={{ textDecoration: 'none' }}>
              {({ isActive }) => (
                <Tooltip title="Open notifications">
                  <IconButton
                    onClick={handleOpenNotificationMenu}
                    sx={{ p: 0 }}
                    color={isActive ? 'primary' : 'default'}
                  >
                    <Badge
                      badgeContent={notifications?.unreadCount}
                      color="primary"
                    >
                      <NotificationsIcon />
                    </Badge>
                  </IconButton>
                </Tooltip>
              )}
            </NavLink>
          </Box>
          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Logout">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                {user && user.avatar && user.avatar.length > 0 ? (
                  <UserAvatar
                    token={userInfo.token}
                    imageUrl={user.avatar}
                    alt={user.name}
                    width={30}
                    height={30}
                  />
                ) : (
                  <AccountCircleIcon />
                )}
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem onClick={handleLogout}>
                <Typography textAlign="center">Logout</Typography>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default Header;
