// src/App.js

import { ThemeProvider } from '@mui/material';
import * as Sentry from '@sentry/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Suspense, lazy, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import ReactGA from 'react-ga4';
import { useDispatch, useSelector } from 'react-redux';
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
  useLocation,
} from 'react-router-dom';
import './App.css';
import { logout } from './actions/userActions.js';
import Footer from './components/Footer.jsx';
import Header from './components/Header.jsx';
import PopupManager from './components/PopupManager.jsx';
import theme from './components/Theme.jsx';
import LoadingIndicatorBackdrop from './components/loadingIndicators/LoadingIndicatorBackdrop.jsx';
import { GA_TRACKING_ID } from './constants/appConstants.js';
import isTokenValid from './utils/isTokenValid.js';
import setAuthToken from './utils/setAuthToken.js';
import { getFallbackPath } from './services/authService';
// import LoginNotificationPrompt from './components/LoginNotificationPrompt';

// Screens already using custom fallback (keep unchanged)
const AvailableMasseusesToday = lazy(() =>
  import('./screens/AvailableMasseusesToday.jsx')
);
const FeedScreen = lazy(() => import('./screens/FeedScreen.jsx'));

// Convert remaining screens to lazy imports
const AppointmentBookingScreen = lazy(() =>
  import('./screens/AppointmentBookingScreen.jsx')
);
const EmailVerifyScreen = lazy(() => import('./screens/EmailVerifyScreen.jsx'));
const FeedbackScreen = lazy(() => import('./screens/FeedbackScreen.jsx'));
const GuestNotificationScreen = lazy(() =>
  import('./screens/GuestNotificationScreen.jsx')
);
const MasseuseDetailsScreen = lazy(() =>
  import('./screens/MasseuseDetailsScreen.jsx')
);
const MorphScreen = lazy(() => import('./screens/MorphScreen.jsx'));
const NotificationListScreen = lazy(() =>
  import('./screens/NotificationListScreen.jsx')
);
const PhoneVerifyScreen = lazy(() => import('./screens/PhoneVerifyScreen.jsx'));
const PreferencesScreen = lazy(() => import('./screens/PreferencesScreen.jsx'));
const ProfilePasswordEditScreen = lazy(() =>
  import('./screens/ProfilePasswordEditScreen.jsx')
);
const ProfileScreen = lazy(() => import('./screens/ProfileScreen.jsx'));
const ProfileUpdateScreen = lazy(() =>
  import('./screens/ProfileUpdateScreen.jsx')
);
const RegisterScreen = lazy(() => import('./screens/RegisterScreen.jsx'));
const ResetPasswordConfirmScreen = lazy(() =>
  import('./screens/ResetPasswordConfirmScreen.jsx')
);
const ResetPasswordScreen = lazy(() =>
  import('./screens/ResetPasswordScreen.jsx')
);
const ResetPasswordVerifyScreen = lazy(() =>
  import('./screens/ResetPasswordVerifyScreen.jsx')
);
const ReviewUsScreen = lazy(() => import('./screens/ReviewUsScreen.jsx'));
const RewardsScreen = lazy(() => import('./screens/RewardsScreen.jsx'));
const ScheduleScreen = lazy(() => import('./screens/ScheduleScreen.jsx'));
const SessionScreen = lazy(() => import('./screens/SessionScreen.jsx'));
const SurveyScreen = lazy(() => import('./screens/SurveyScreen.jsx'));
const TransactionListScreen = lazy(() =>
  import('./screens/TransactionListScreen.jsx')
);
const EmailSurveyScreen = lazy(() => import('./screens/EmailSurveyScreen.jsx'));
const ServicesRatesScreen = lazy(() =>
  import('./screens/ServicesRatesScreen.jsx')
);
const ThankYouSurveyScreen = lazy(() =>
  import('./screens/ThankYouSurveyScreen.jsx')
);
const ConversationScreen = lazy(() =>
  import('./screens/ConversationScreen.jsx')
);
const LoginScreen = lazy(() => import('./screens/LoginScreen.jsx'));
const ConversationWithScreen = lazy(() =>
  import('./screens/ConversationWithScreen.jsx')
);
const MasseuseScreen = lazy(() => import('./screens/MasseuseScreen.jsx'));

ReactGA.initialize(GA_TRACKING_ID);

// Set auth token once if user info exists in localStorage
if (localStorage.userInfo) {
  setAuthToken(JSON.parse(localStorage.userInfo).token);
}

/**
 * Component to track page views on route changes.
 */
function AnalyticsTracker() {
  const location = useLocation();
  useEffect(() => {
    const page = location.pathname + location.search;
    ReactGA.send({
      hitType: 'pageview',
      page,
      title: `Viewed page: ${page}`,
    });
  }, [location]);
  return null;
}

function App() {
  const dispatch = useDispatch();
  const queryClient = new QueryClient();
  const [redirect, setRedirect] = useState('/feed');
  const [logOutClicked, setLogOutClicked] = useState(false);
  const [cookies] = useCookies(['cookieConsent']);
  const { userInfo } = useSelector((state) => state.userLogin);

  // Set auth token and determine redirect from query params
  useEffect(() => {
    if (localStorage.userInfo) {
      setAuthToken(JSON.parse(localStorage.userInfo).token);
    }
    const queryParams = new URLSearchParams(window.location.search);
    const redirectParam = queryParams.get('redirect');
    if (redirectParam) {
      setRedirect(redirectParam);
    }

    return () => {
      setAuthToken(null);
    };
  }, []);

  // Validate token on mount and dispatch logout if invalid
  useEffect(() => {
    if (!isTokenValid()) {
      dispatch(logout());
    }
  }, [dispatch]);

  // Context menu prevention in production environment
  useEffect(() => {
    const handleContextMenu = (e) => e.preventDefault();
    if (process.env.NODE_ENV === 'production') {
      document.addEventListener('contextmenu', handleContextMenu);
    }
    return () => {
      document.removeEventListener('contextmenu', handleContextMenu);
    };
  }, []);

  // Compute fallback redirection path using the auth service
  const fallbackPath = getFallbackPath(userInfo, redirect, logOutClicked);

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <Router>
          {/* AnalyticsTracker will send pageview events on route change */}
          <AnalyticsTracker />
          <PopupManager />
          {userInfo && (
            <Header theme={theme} setLogOutClicked={setLogOutClicked} />
          )}
          <Routes>
            {/* Public Routes */}
            <Route
              path="/survey"
              element={
                <Suspense fallback={<LoadingIndicatorBackdrop />}>
                  <EmailSurveyScreen />
                </Suspense>
              }
            />
            <Route
              path="/survey/thank-you"
              element={
                <Suspense fallback={<LoadingIndicatorBackdrop />}>
                  <ThankYouSurveyScreen />
                </Suspense>
              }
            />
            {!userInfo && (
              <>
                <Route
                  path="/login"
                  element={
                    <Suspense fallback={<LoadingIndicatorBackdrop />}>
                      <LoginScreen />
                    </Suspense>
                  }
                />
                <Route
                  path="/morph"
                  element={
                    <Suspense fallback={<LoadingIndicatorBackdrop />}>
                      <MorphScreen />
                    </Suspense>
                  }
                />
                <Route
                  path="/available"
                  element={
                    <Suspense fallback={<LoadingIndicatorBackdrop />}>
                      <AvailableMasseusesToday />
                    </Suspense>
                  }
                />
                <Route
                  path="/spatrack"
                  element={
                    <Suspense fallback={<LoadingIndicatorBackdrop />}>
                      <GuestNotificationScreen />
                    </Suspense>
                  }
                />
                <Route
                  path="/register"
                  element={
                    <Suspense fallback={<LoadingIndicatorBackdrop />}>
                      <RegisterScreen />
                    </Suspense>
                  }
                />
                <Route
                  path="/reset"
                  element={
                    <Suspense fallback={<LoadingIndicatorBackdrop />}>
                      <ResetPasswordScreen />
                    </Suspense>
                  }
                />
                <Route
                  path="/reset/verify"
                  element={
                    <Suspense fallback={<LoadingIndicatorBackdrop />}>
                      <ResetPasswordVerifyScreen />
                    </Suspense>
                  }
                />
                <Route
                  path="/reset/confirm"
                  element={
                    <Suspense fallback={<LoadingIndicatorBackdrop />}>
                      <ResetPasswordConfirmScreen />
                    </Suspense>
                  }
                />
              </>
            )}

            {/* Private (Authenticated) Routes */}
            {userInfo && (
              <>
                <Route
                  path="/review-us"
                  element={
                    <Suspense fallback={<LoadingIndicatorBackdrop />}>
                      <ReviewUsScreen />
                    </Suspense>
                  }
                />
                <Route
                  path="/masseuses"
                  element={
                    <Suspense fallback={<LoadingIndicatorBackdrop />}>
                      <MasseuseScreen />
                    </Suspense>
                  }
                />
                <Route
                  path="/"
                  element={
                    <Suspense fallback={<LoadingIndicatorBackdrop />}>
                      <RewardsScreen />
                    </Suspense>
                  }
                />
                <Route
                  path="/masseuse/:id"
                  element={
                    <Suspense fallback={<LoadingIndicatorBackdrop />}>
                      <MasseuseDetailsScreen />
                    </Suspense>
                  }
                />
                <Route
                  path="/sessions"
                  element={
                    <Suspense fallback={<LoadingIndicatorBackdrop />}>
                      <SessionScreen />
                    </Suspense>
                  }
                />
                <Route
                  path="/sessions/survey/:id"
                  element={
                    <Suspense fallback={<LoadingIndicatorBackdrop />}>
                      <SurveyScreen />
                    </Suspense>
                  }
                />
                <Route
                  path="/profile"
                  element={
                    <Suspense fallback={<LoadingIndicatorBackdrop />}>
                      <ProfileScreen />
                    </Suspense>
                  }
                />

                <Route
                  path="/profile/email_confirm"
                  element={
                    <Suspense fallback={<LoadingIndicatorBackdrop />}>
                      <EmailVerifyScreen />
                    </Suspense>
                  }
                />
                <Route
                  path="/profile/phone_confirm"
                  element={
                    <Suspense fallback={<LoadingIndicatorBackdrop />}>
                      <PhoneVerifyScreen />
                    </Suspense>
                  }
                />
                <Route
                  path="/profile/update"
                  element={
                    <Suspense fallback={<LoadingIndicatorBackdrop />}>
                      <ProfileUpdateScreen />
                    </Suspense>
                  }
                />
                <Route
                  path="/profile/change-password"
                  element={
                    <Suspense fallback={<LoadingIndicatorBackdrop />}>
                      <ProfilePasswordEditScreen />
                    </Suspense>
                  }
                />
                <Route
                  path="/transactions"
                  element={
                    <Suspense fallback={<LoadingIndicatorBackdrop />}>
                      <TransactionListScreen />
                    </Suspense>
                  }
                />
                <Route
                  path="/notifications"
                  element={
                    <Suspense fallback={<LoadingIndicatorBackdrop />}>
                      <NotificationListScreen />
                    </Suspense>
                  }
                />
                <Route
                  path="/feedbacks"
                  element={
                    <Suspense fallback={<LoadingIndicatorBackdrop />}>
                      <FeedbackScreen />
                    </Suspense>
                  }
                />
                <Route
                  path="/preferences"
                  element={
                    <Suspense fallback={<LoadingIndicatorBackdrop />}>
                      <PreferencesScreen />
                    </Suspense>
                  }
                />
                <Route
                  path="/feed"
                  element={
                    <Suspense fallback={<LoadingIndicatorBackdrop />}>
                      <FeedScreen />
                    </Suspense>
                  }
                />
                <Route
                  path="/schedule"
                  element={
                    <Suspense fallback={<LoadingIndicatorBackdrop />}>
                      <ScheduleScreen />
                    </Suspense>
                  }
                />
                <Route
                  path="/services-rates"
                  element={
                    <Suspense fallback={<LoadingIndicatorBackdrop />}>
                      <ServicesRatesScreen />
                    </Suspense>
                  }
                />
                <Route
                  path="/appointment"
                  element={
                    <Suspense fallback={<LoadingIndicatorBackdrop />}>
                      <AppointmentBookingScreen />
                    </Suspense>
                  }
                />
                <Route
                  path="/conversations"
                  element={
                    <Suspense fallback={<LoadingIndicatorBackdrop />}>
                      <ConversationScreen />
                    </Suspense>
                  }
                />
                <Route
                  path="/conversations/:id"
                  element={
                    <Suspense fallback={<LoadingIndicatorBackdrop />}>
                      <ConversationWithScreen />
                    </Suspense>
                  }
                />
              </>
            )}

            {/* Fallback Route */}
            <Route path="*" element={<Navigate to={fallbackPath} />} />
          </Routes>
          {userInfo && <Footer theme={theme} />}
          {/* <LoginNotificationPrompt /> */}
          {/* <CookieConsent cookieConsent={cookies.cookieConsent} /> */}
        </Router>
      </ThemeProvider>
    </QueryClientProvider>
  );
}

export default Sentry.withProfiler(App);
