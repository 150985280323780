import { Backdrop, Box, CircularProgress } from '@mui/material';
import React from 'react';

const LoadingIndicatorBackdrop = () => {
  return (
    <Box
      sx={{
        height: '90vh',
      }}
    >
      <Backdrop
        sx={{
          zIndex: 999,
        }}
        open={true}
      >
        <CircularProgress color="primary" />
      </Backdrop>
    </Box>
  );
};

export default LoadingIndicatorBackdrop;
