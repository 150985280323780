import { useState, useEffect } from 'react';
import axios from 'axios';

function useImageSrc(token, imageUrl) {
  const [imageSrc, setImageSrc] = useState(null);

  useEffect(() => {
    const fetchImage = async () => {
      try {
        const response = await axios.get(imageUrl, {
          headers: {
            Authorization: token,
          },
          responseType: 'blob', // Important for handling binary data
        });
        const blob = response.data;
        const objectURL = URL.createObjectURL(blob);
        setImageSrc(objectURL);
      } catch (error) {
        console.error(error);
      }
    };

    if (!imageUrl || !token || imageUrl.length === 0) {
      return () => {
        setImageSrc(null);
      };
    } else {
      fetchImage();
    }
  }, [token, imageUrl]);

  return imageSrc;
}

export default useImageSrc;
