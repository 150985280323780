/**
 * Service to handle authentication and routing logic
 */

/**
 * Determines if a redirect parameter should be added to the login URL
 * @param {string} currentPath - The current path being accessed
 * @returns {string|null} The redirect URL or null if no redirect should be added
 */
export const shouldAddRedirect = (currentPath) => {
  // Don't add redirect for these paths
  const noRedirectPaths = ['/login', '/register', '/reset'];

  // If current path is in noRedirectPaths, return null
  if (noRedirectPaths.includes(currentPath)) {
    return null;
  }

  // For root path, don't add redirect
  if (currentPath === '/') {
    return null;
  }

  return currentPath;
};

/**
 * Determines the target path after successful login
 * @param {Object} userInfo - The user info object from Redux state
 * @param {string} redirect - The redirect path from URL params
 * @returns {string} The target path to navigate to
 */
export const getLoginTargetPath = (userInfo, redirect) => {
  if (!userInfo) return '/login';

  if (userInfo.user?.fresh_login) {
    return `/profile/update?fresh_login=${userInfo.user.fresh_login}`;
  }

  // If no redirect specified, go to feed
  return redirect || '/feed';
};

/**
 * Determines the fallback path based on authentication state
 * @param {Object} userInfo - The user info object from Redux state
 * @param {string} redirect - The redirect path from URL params
 * @param {boolean} logOutClicked - Whether logout was clicked
 * @returns {string} The fallback path to navigate to
 */
export const getFallbackPath = (userInfo, redirect, logOutClicked) => {
  if (userInfo) {
    if (userInfo.user?.fresh_login) {
      return `/profile/update?fresh_login=${userInfo.user.fresh_login}`;
    }
    return redirect || '/feed';
  }

  // If not logged in
  if (logOutClicked || window.location.pathname === '/') {
    return '/login';
  }

  const redirectPath = shouldAddRedirect(window.location.pathname);
  return redirectPath
    ? `/login?redirect=${encodeURIComponent(redirectPath)}`
    : '/login';
};
