import { jwtDecode } from 'jwt-decode'; // Make sure to have this dependency installed

const isTokenValid = () => {
  if (localStorage.userInfo) {
    const token = JSON.parse(localStorage.userInfo).token;
    const decoded = jwtDecode(token);

    if (Date.now() >= decoded.exp * 1000) {
      return false; // Token has expired
    } else {
      return true; // Token is still valid
    }
  } else {
    return false; // No token found in localStorage
  }
};

export default isTokenValid;
